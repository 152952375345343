<template>
  <div style="display: flex;justify-content: center;width: 100% ">
    <a-list size="large" v-show="rulesId === ''" item-layout="vertical" :pagination="pagination" :data-source="data" style="width: 95%;cursor: pointer;">
      <a-list-item slot="renderItem" slot-scope="item" @click="clickList(item.id)">
        {{ item.title }}
      </a-list-item>
    </a-list>
    <TransactionRule v-if="rulesId === '2001'"></TransactionRule>
  </div>
</template>

<script>
import TransactionRule from 'views/rule/rules/detail/TransactionRule'

const data = [
  { title: '交易安全规则', id: '2001' }
]
export default {
  name: 'TransactionDefault',
  components: { TransactionRule },
  props: ['flush'],
  data() {
    return {
      data,
      rulesId: '',
      pagination: {
        onChange: page => {
          console.log(page)
        },
        pageSize: 10
      }
    }
  },
  watch: {
    flush(nV, oV) {
      if (nV !== oV) {
        this.rulesId = ''
      }
    }
  },
  methods: {
    clickList(id) {
      this.rulesId = id
    }
  }
}
</script>

<style scoped>

</style>
