<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>禁售规则</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p>下面的活动是被禁止的：</p>
      <p class="up">1.儿童剥削</p>
      <p>您不得提供与非法利用或儿童权益相关的任何商品和权利，不得上传、上传此类物品，但也不得用于宣传服务，也不得用于宣传或发布性行为，不得以性方式展示、儿童的图片或性行为，或以违反社会公序良俗的任何方式展示儿童的相关内容。</p>
      <p class="up">2.骚扰、欺凌、诽谤和威胁</p>
      <p>您不得骚扰、欺凌、发布、发布、威胁特定个人的商品或服务，不得侵犯或提供您的个人信息和隐私。</p>
      <p class="up">3.有害内容</p>
      <p>您不得通过基于种族、种族、种族、种族、宗教、种族、性别、种族、性别、性别、状况或退伍身份或退伍军人身份或对人的暴力和使用服务态度的歧视行为，也不得发布上传此类材料。您不得使用我们的服务来纵容或支持任何有自身行为的组织，平台或个人：）宣传或以容貌（i）或（ii）威胁纵容强行决定某位种目的。</p>
      <p class="up">4.不寻常的活动</p>
      <p>您也不得提供或发布您的申请、及运营业务或管辖范围内的法律法规，以及政府的商品服务，不得上传此类材料。您提供的商品、服务及发布上传的材料也不得帮助或宣传违反您在司法管辖区经营或开展业务的任何法律法规及政府的行为。</p>
      <p class="up">5.知识产权及商业秘密</p>
      <p>您不得提供他人知识产权、著作权、商标权、专利权及商业秘密的商品或服务，不得发布上传或其他类似材料。</p>
      <p class="up">6.恶意和欺骗性的行为</p>
      <p>您不得使用上传服务传播有害软件或主机的照片或进行损害或网络Vllshop。 。您不得将本服务用于欺诈性的商业行为或任何其他不正当或欺诈性行为。</p>
      <p class="up">7.个人姓名、信息和受保护的健康信息</p>
      <p>您不得上传包含任何个人信息，获得的敏感信息或保密信息（如您的身份证号码、国家身份身份证明个人身份信息或您的账户或密码）的，已获得该信息发布人的其他授权信息授权人的同意。</p>
      <p class="up">8.限制物品</p>
      <p>您不得提供属于任何物品或服务的物品。</p>
      <p class="up">9.亲身伤害</p>
      <p>您不得以他人的形式提供自己与其他任何与身体行为相关的或与他人的任何行为相关的，也不得发布以娱乐形式的商品服务的危急情况或物品的危害。难得的待遇。</p>
      <p class="up">10.垃圾邮件</p>
      <p>您可以随意使用任何服务传输任何未经允许的Vllshop商业电子信息。</p>
      <p class="up">11.今天组织</p>
      <p>您不得提供建议或宣传支持、支持或参与组织的商品或服务，以及任何此类。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'NoSaleRule'
}
</script>

<style scoped>

</style>
