<template>
  <div style="display: flex;justify-content: center;width: 100% ">
    <a-list v-show="rulesId === ''"  size="large" item-layout="vertical" :pagination="pagination" :data-source="data" style="width: 95%;cursor: pointer;">
      <a-list-item slot="renderItem" slot-scope="item"  @click="clickList(item.id)">
        {{ item.title }}
      </a-list-item>
    </a-list>
    <ShopLimitRule v-if="rulesId === '2000'"></ShopLimitRule>
    <NoSaleRule v-if="rulesId === '2002'"></NoSaleRule>
    <PunishRule v-if="rulesId === '2003'"></PunishRule>
    <IndustryRule v-if="rulesId === '2004'"></IndustryRule>
    <IntellectualPropertyRule v-if="rulesId === '2005'"></IntellectualPropertyRule>
  </div>
</template>

<script>
import ShopLimitRule from 'views/rule/rules/detail/ShopLimitRule'
import NoSaleRule from 'views/rule/rules/detail/NoSaleRule'
import PunishRule from 'views/rule/rules/detail/PunishRule'
import IndustryRule from 'views/rule/rules/detail/IndustryRule'
import IntellectualPropertyRule from 'views/rule/rules/detail/IntellectualPropertyRule'

const data = [
  { title: 'vllshop 店铺限定规则', id: '2000' },
  { title: '禁售规则', id: '2002' },
  { title: '商品违法处罚规则', id: '2003' },
  { title: '行业管理规范', id: '2004' },
  { title: '知识产权规则', id: '2005' }
]
export default {
  name: 'InfoDefault',
  components: { IntellectualPropertyRule, IndustryRule, PunishRule, NoSaleRule, ShopLimitRule },
  props: ['flush'],
  data() {
    return {
      data,
      rulesId: '',
      pagination: {
        onChange: page => {
          console.log(page)
        },
        pageSize: 10
      }
    }
  },
  watch: {
    flush(nV, oV) {
      if (nV !== oV) {
        this.rulesId = ''
      }
    }
  },
  methods: {
    clickList(id) {
      this.rulesId = id
    }
  }
}
</script>

<style scoped>

</style>
