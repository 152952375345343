<template>
  <a-input-search
    v-model.trim="inputValue"
    style="width: 100%;height: 54px"
    placeholder="请输入标题规则"
    enter-button
    size="large"
    @search="onSearch"
  />
</template>

<script>
export default {
  name: 'RuleSearchInput',
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    onSearch() {
      if (this.inputValue !== '') {
        this.$router.push({ path: '/ruleCenter/RuleSearch',
          query: { keywords: this.inputValue }})
      }
    }
  }
}
</script>

<style scoped>

</style>
