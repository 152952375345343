<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>商品违法处罚规则</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p class="up">发布的广告、价格内容及商品质量应符合相关法律法规</p>
      <p class="up">1、商家不得实施价格欺诈行为：</p>
      <p>所有商户的价格均应严格遵守法律规定、市场价格跟踪规律，填写价格确保有真实有效的实际价格（如价格标识）。虚构原价、市场价等，不得优惠促销。</p>
      <p class="up">2、商品的真实性、价格合理性：</p>
      <p>商家对商品/工具进行折扣促销的，商家应确保折价的基准价为商品服务的“吊价或官方公开价格”，打打类划线价、折扣、成交价的真实性、正确、合法性负责。</p>
      <p class="up">3、商品真实价格要求：</p>
      <p>对商品/服务进行营销的，必须满足所有划线价、拟对价的真实性、规定、合法性；划线价为满足及本规范的真实价格。</p>
      <p class="up">4、商家发布商品，对商品完整、一致、真实的描述：</p>
      <p>1） 了解：为确保买家更全面了解商品，购买商品时应充分知情权，商家应在发布时完整展示商品的主要信息，包括不：商品时本身（基本、规格、保质期）瑕疵等、品牌、外包装、货物情况、交易物品等。</p>
      <p>2）一致性：商品的描述信息在商品页面各版块中（如商品标题、主图、属性、描述等）应保证一致性。</p>
      <p>3）性：商家根据所应商品的真实情况描述商品信息，及时更新销售，商品信息真实维护、正确、有效；保证夸大、过度、宣传、宣传，同时不得保证商品的真实性及效果及程度等。广告数据使用、统计资料、调查结果、文摘、引语等引证内容的直观、真实、准确，并出处。</p>
      <p class="up">5、商家应保证其销售的商品符合生产质量要求，并可正常使用：</p>
      <p>商户销售的商品质量合格符合产地的商品国家标准，同时应保证商品不存在期限及瑕疵重大、缺陷、危及财产的不合理等问题，商户销售的商品保证商品具有的使用性能、符合条件商品可以在包装上采用标准等。</p>
      <p class="up">6、不得发布违反法律法规、vllshop各项协议或规则的商品信息。</p>
      <p class="up">如若违反上述条款，平台对商品违规行为的处理方式，平台发布商品的企业进行处理的处理，包括但不承担责任追究、限制访问、追究责任等责任。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PunishRule'
}
</script>

<style scoped>

</style>
