<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>Vllshop 店铺限定规则</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p class="up">店铺名不得含有以下内容：</p>
      <p>1.有损国家、社会公共利益，或有损民族尊严的；</p>
      <p>2. 他人合法权益的；</p>
      <p>3.夸大宣传以夸夸其谈，引人误解的标题、消费者；</p>
      <p>4.国际组织的名称；</p>
      <p>5.非政治国家领导人姓名、但政党名称、党政军机关名称；</p>
      <p>6. 带有糟粕性、有政治影响，或有不当的封建风格、文化歧视的、或涉及种族歧视的</p>
      <p>7.包含未经vllshop授权、许可使用的名称、标识等信息，如包含“vllshop”及类似含义或名称的词语；</p>
      <p>8.未取得认证品牌或授权的店铺，不得使用旗舰店、专卖店、专营店等词汇；</p>
      <p>9.其他违反法律行为或社会风俗的。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopLimitRule'
}
</script>

<style scoped>

</style>
