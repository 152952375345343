<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>Vllshop关于退货商品</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p class="up">以下任意一种即会被定义为“行为违法，若有”：</p>
      <p>（一）店铺上传的订单单号与订单不匹配，如物流配送信息与订单更新收货地不、物流时间早于下单物流/发货时间；</p>
      <p>（二）点击更新后无48小时内实际物流配送订单，或者使用订单号；</p>
      <p>（三）多个收货信息不同的订单使用同一个物流单号；</p>
      <p>（四）选择商品为采购，且配送方式为“快递”，“无需物流”；</p>
      <p>（五）其他货物将发出，但未显示真实的行为。</p>
      <p class="up">处罚措施：</p>
      <p>Vllshop观察严重但严重程度采取相应措施，包括不针对商品下架、限制访问、交易、限制等时刻处理。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnGoods'
}
</script>

<style scoped>

</style>
