<template>
  <div class="rule">
    <div class="mask">
      <a-row type="flex" justify="center" style="width:100%">
        <a-col :xl="9" :lg="12" :md="15" :span="22">
          <RuleSearchInput></RuleSearchInput>
        </a-col>
      </a-row>
    </div>
    <div style="padding: 0 236px;min-height:calc(100% - 400px)">
      <a-tabs :default-active-key="defaultActiveKey" :size="'large'" style="text-align: left;font-size: 32px" @change="callback">
        <a-tab-pane key="1" tab="公告">
          <div style="display: flex">
            <a-menu v-model="current" style="width: 256px;font-size: 32px;">
              <a-menu-item key="1001">
                Vllshop关于商品、订单通知及申请FAQ
              </a-menu-item>
              <a-menu-item key="1000">
                Vllshop关于发布特殊品类商品信息公告
              </a-menu-item>
              <a-menu-item key="1002">
                Vllshop关于退货商品
              </a-menu-item>
              <a-menu-item key="1003">
                Vllshop关于知识产权投诉公告
              </a-menu-item>
            </a-menu>
            <ReleaseSpecialProducts v-show="current[0]==='1000'" />
            <ShopFAQ v-show="current[0]==='1001'" />
            <return-goods v-show="current[0]==='1002'" />
            <IntellectualProperty v-show="current[0]==='1003'" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="规则" force-render>
          <div style="display: flex">
            <a-menu v-model="rulesCurrent" style="width: 256px;font-size: 32px" @click="flushD()">
              <a-menu-item key="2000">
                交易规则
              </a-menu-item>
              <a-menu-item key="2001">
                内容与信息发布
              </a-menu-item>
            </a-menu>
            <TransactionDefault :flush="flush" v-if="rulesCurrent[0] === '2000'"></TransactionDefault>
            <InfoDefault :flush="flush" v-if="rulesCurrent[0] === '2001'"></InfoDefault>
            <NoticeDefault :ruleId="ruleId" v-if="rulesCurrent[0] === '1000'" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>

import ReleaseSpecialProducts from 'views/rule/notice/ReleaseSpecialProducts'
import ShopFAQ from 'views/rule/notice/ShopFAQ'
import ReturnGoods from 'views/rule/notice/ReturnGoods'
import IntellectualProperty from 'views/rule/notice/IntellectualProperty'
import NoticeDefault from 'views/rule/rules/NoticeDefault'
import InfoDefault from 'views/rule/rules/InfoDefault'
import TransactionDefault from 'views/rule/rules/TransactionDefault'
import RuleSearchInput from 'views/rule/search/RuleSearchInput'

export default {
  name: 'RuleHome',
  components: { RuleSearchInput, TransactionDefault, InfoDefault, NoticeDefault, IntellectualProperty, ReturnGoods, ShopFAQ, ReleaseSpecialProducts },
  data() {
    return {
      rulesCurrent: ['1000'],
      current: ['1001'],
      defaultActiveKey: '1',
      noticeList: ['1000', '1001', '1002', '1003'],
      rulesList: ['2000', '2001'],
      ruleId: '',
      flush: '刷新'
    }
  },
  created() {
    if (this.$route.query.activeKey === '1' || this.$route.query.activeKey === '2') {
      this.defaultActiveKey = this.$route.query.activeKey
      if (this.$route.query.current) {
        this.setListIndex(this.$route.query.activeKey, this.$route.query.current)
      }
    }
    if (this.$route.query.ruleId) {
      this.ruleId = this.$route.query.ruleId
      console.log(this.$route.query.ruleId)
    }
  },
  methods: {
    onSearch(value) {
      console.log(value)
    },
    flushD() {
      this.flush = Math.random()
    },
    callback(key) {
      console.log(key)
    },
    setListIndex(ActiveKey, current) {
      if (ActiveKey === '1') {
        if (this.noticeList.indexOf(current) === -1) {
          return false
        } else {
          this.current[0] = this.$route.query.current
          return true
        }
      }

      if (ActiveKey === '2') {
        if (this.rulesList.indexOf(current) === -1) {
          return false
        } else {
          this.rulesCurrent[0] = this.$route.query.current
          return this
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .mask{
    width: 100%;
    height: 240px;
    position: relative;
    background-image: url('/images/Mask_Group.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .ant-input-lg{
    height: 54px !important;
  }
  ::v-deep .mask .ant-btn-lg{
    height: 54px !important;
    width: 80px;
    font-size: 25px;
  }
</style>
